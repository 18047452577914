.range-input {
    -webkit-appearance: none;
    appearance: none;
    width: 100px;
    cursor: pointer;
    outline: none;
    height: 2px;
    background: #7E7E7E;
}

.range-input::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 16px;
    width: 16px;
    background-color: #FFFFFF;
    border-radius: 50%;
    border: none;
    transition: .2s ease-in-out;

    top: 30px;
}

.range-input::-moz-range-thumb {
    height: 16px;
    width: 16px;
    background-color: #FFFFFF;
    border-radius: 50%;
    border: none;
    transition: .2s ease-in-out;

    top: 30px;
}